<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Progress</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(value, index) in values" :key="index" :value="value" :max="max" class="mb-3"></b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Progress Labels</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(value, index) in values" :key="index" :max="max" class="mb-3">
                            <b-progress-bar :value="value" :label="getLabel(value)"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Width and Height</div>
                    <div class="card-body mb-n3">
                        <div>
                            <p>Default and Custom Width</p>
                            <b-progress v-for="(value, index) in values" :key="index" :max="max" :class="['mb-3', {[`w-${value}`]: value !== 100}]">
                                <b-progress-bar value="75" :label="getLabel(75)"></b-progress-bar>
                            </b-progress>
                        </div>
                        <div class="mt-4">
                            <p>Default and Custom Height</p>
                            <b-progress v-for="(value, index) in values" :key="index" :height="getCustomHeight(value)" :max="max" class="mb-3">
                                <b-progress-bar :value="value"></b-progress-bar>
                            </b-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Contextual Backgrounds</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(variant, index) in variations" :key="index" :max="max" :variant="variant" class="mb-3">
                            <b-progress-bar value="75" :label="variant.toUpperCase()"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Multiple bars</div>
                    <div class="card-body">
                        <b-progress :max="max" class="mb-3">
                            <b-progress-bar variant="primary" :value="values[0]"></b-progress-bar>
                            <b-progress-bar variant="success" :value="values[1]"></b-progress-bar>
                            <b-progress-bar variant="danger" :value="values[2]"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Striped</div>
                    <div class="card-body">
                        <div>
                            <p>Default</p>
                            <b-progress v-for="(variant, index) in variations" :key="index" :max="max" :variant="variant" striped class="mb-3">
                                <b-progress-bar value="75"></b-progress-bar>
                            </b-progress>
                        </div>
                        <div class="mt-4">
                            <p>Animated</p>
                            <b-progress v-for="(variant, index) in variations" :key="index" :max="max" :variant="variant" striped animated class="mb-3">
                                <b-progress-bar value="75"></b-progress-bar>
                            </b-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Progress',
        data() {
            return {
                variations: ['primary', 'secondary', 'danger', 'warning', 'success', 'info'],
                values: [25, 50, 75, 100],
                max: 100
            }
        },
        methods: {
            getLabel(value) {
                return `${((value / this.max) * 100).toFixed(2)}%`
            },
            getCustomHeight(value) {
                if (value === 100) return '';

                return `${value / 5}px`;
            }
        }
    }
</script>
